<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <super-dashboard v-if="$auth.getRole()==='super'"></super-dashboard>
            <guardian-dashboard v-else-if="$auth.getRole()==='guardian'"></guardian-dashboard>
            <dashboard v-else></dashboard>
        </v-layout>
    </v-container>
</template>
<script>
    import dashboard from './rolewise/dashboard';
    import SuperDashboard from './rolewise/super';
    import GuardianDashboard from './rolewise/guardian';

    export default {
        components: {
            SuperDashboard,
            GuardianDashboard,
            dashboard
        },
        data: () => ({}),
        methods: {},

        beforeDestroy() {
            // this.$events.remove('notification')
        },
    }
</script>
<style lang="scss">

    .student-card {
        /*background-color: #eee;*/
        display: flex;
        .student-image {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            border: 1px dashed #ccc;
            overflow: hidden;
            margin-right: 15px;
            img {
                width: 100%;
            }
        }
        .student-personal {
            h2 {
                color: #535166;
                margin-bottom: 0;
                line-height: 1;
                + p {
                    color: #888;
                    margin-top: 0;
                    margin-bottom: 3px;
                }
            }
            ul {
                margin-top: 10px;
                /*padding: 0;*/
                /*background: #deedfd;*/
                border-radius: 2px;
                padding: 5px 15px 0 0;
                border-top: 1px solid #ccc;
                li {
                    list-style: none;
                    color: #777;
                    display: inline-block;
                    margin-right: 60px;
                    small {
                        margin-bottom: 0;
                    }
                    h5 {
                        margin-top: 0;
                        line-height: 1;
                        color: #4489d6;
                        font-size: 16px;
                    }
                }
            }
        }

        .view-more-prof {
            margin-left: auto;
            margin-right: 20px;
            align-self: center;
            a {
                text-decoration: none;
                border: 1px solid #ccc;
                padding: 10px 20px;
                border-radius: 20px;
                background-color: #eee;
                font-weight: bold;
            }
        }
    }

    .dash-container {
        /*padding: 0 !important;*/
        .info-tab-section {
            padding-bottom: 20px;
            .theme--light {
                background-color: transparent !important;
            }
        }
    }

    .v-tabs__item {
        color: #888;
    }

    .v-tabs__item--active {
        color: #1976d2 !important;
        font-weight: bold;
    }

    .chart-container {
        padding: 20px 0 !important;
        margin-left: -60px;
        min-height: 330px;

    }

    .app-features {
        display: flex;
        min-height: 120px;
        background-color: white;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        .feature-icon {
            flex: 1.3;
            align-items: center;
            justify-content: center;
            display: flex;
            /*background-color: red;*/
        }
        .feature-content {
            flex: 3;
            background-color: white;
            /*padding: 15px 15px;*/
            display: flex;
            align-items: center;
            justify-content: center;
            .content {
                p {
                    color: #777;
                    line-height: 15px;
                    font-size: 13px;
                }
            }
            h3 {
                font-size: 18px;
            }
            h2 {
                color: #555;
                line-height: 14px;
                font-size: 20px;
                font-weight: 300;
            }
        }
        &.f-books {
            .feature-icon {
                background: -webkit-linear-gradient(45deg, #FF5252 0%, #f48fb1 100%);
                background: linear-gradient(45deg, #FF5252 0%, #f48fb1 100%);
            }
            h3 {
                color: #FF5252;
            }
        }
        &.f-sms {
            .feature-icon {
                background: -webkit-linear-gradient(45deg, #43A047 0%, #1de9b6 100%);
                background: linear-gradient(45deg, #43A047 0%, #1de9b6 100%);
            }
            h3 {
                color: #43A047;
            }
        }
        &.f-converter {
            .feature-icon {
                background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
                background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
            }
            h3 {
                color: #0288d1;
            }
        }
        &.f-collection {
            .feature-icon {
                background: -webkit-linear-gradient(45deg, #b199dd 0%, #a389d3 100%);
                background: linear-gradient(45deg, #b199dd 0%, #a389d3 100%);
            }
            h3 {
                color: #a389d3;
            }
        }
    }

    .background-amount {
        background: white;
        background: -webkit-linear-gradient(45deg, white 0%, #dddddd 100%);
        background: linear-gradient(45deg, white 0%, #dddddd 100%);
    }

    .background-converter {
        background: #FF5252;
        background: -webkit-linear-gradient(45deg, #FF5252 0%, #f48fb1 100%);
        background: linear-gradient(45deg, #FF5252 0%, #f48fb1 100%);
    }

    .background-books {
        background: #0288d1;
        background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
        background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
    }

    .background-sms {
        background: #43A047;
        background: -webkit-linear-gradient(45deg, #43A047 0%, #1de9b6 100%);
        background: linear-gradient(45deg, #43A047 0%, #1de9b6 100%);
    }

    .dash-container {
        min-height: 100px;
        width: 90%;
        /*padding: 40px 0 0 0;*/
        margin: 0 auto;
        div.dash-company-info {
            padding-top: 20px;
            display: flex;
            padding-bottom: 10px;
        }
        h1 {
            color: #555;
            font-weight: 300;
            + p {
                color: #888;
                font-size: 14px;
            }
        }
        .navigate-to {
            flex: 1.5;
            padding-top: 15px;
            ul li {
                list-style: none;
                display: inline;
                a {
                    display: inline-block;
                    border: 0.15em solid #c9c9c9;
                    margin-right: 10px;
                    height: 25px;
                    font-size: 10px;
                    width: 25px;
                    text-align: center;
                    line-height: 25px;
                    border-radius: 50px;
                    text-decoration: none;
                    &:hover {
                        i {
                            color: #333 !important;
                        }
                    }
                }
            }
        }
        .intro {
            flex: 3;
        }
    }

    .profile-user-info {
        display: flex;
        .profile-image {
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
            border-radius: 200px;
            overflow: hidden;
            flex: 1;
            border: 1px solid white;
            img {
                margin: 0 auto 0 -10px;
                padding: 0;
                width: 118%;
            }
            width: 100px;
            height: 106px;
            /*background-color: red;*/
        }
        .profile-info {
            ul {
                margin: 0;
                padding: 0;
            }
            flex: 2;
            color: #555;
            padding-left: 15px;
            h3 {
                font-weight: normal;
                color: #333;
            }
            p {
                line-height: 15px;
            }
            .name-info {
                font-size: 14px;
                margin-bottom: 5px;
                color: #888;
                font-weight: bold;
                line-height: 10px;
                margin-bottom: 10px;
            }
            .email-info {
                color: #999999;
                font-size: 12px;
                padding-top: 0;
                margin-bottom: 10px;
            }

            .profile-nav {
                padding-top: 10px;
                border-top: 1px solid #e3e3e3;
            }
            /*background-color: green;*/
        }
    }

</style>